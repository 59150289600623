<div class="flex flex-1 flex-col px-5">

  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Nova campanha</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie uma nova campanha</h6>
    <hr />
  </div>
  <form [formGroup]="formGroup">
    <div class="flex gap-2">
      <div class="flex gap-3 mb-3 w-full">
        <div class="flex flex-col w-[100%]">
          <label class="font-semibold text-black">Nome da campanha *</label>
          <input class="input-field" maxlength="100" formControlName="name" placeholder="ex: Campanha">
          <field-error-component [field]="'name'" [label]="'Nome da campanha'"
            [formGroup]="formGroup"></field-error-component>
        </div>
      </div>

      <div class="flex gap-3 w-[29.5%]">
        <div class="flex flex-col w-[100%]">
          <label class="font-semibold text-black">Canal *</label>
          <mat-select class="input-field" formControlName="channel" (selectionChange)="selectChannel($event.value)"
            placeholder="Selecione" #channel>
            <div class="w-full h-[35px] p-2 mb-2">
              <input style="height: 35px !important;" class="input-field" type="text" (focus)="onFocusChannel()"
                [formControl]="filterControlChannel" placeholder="Pesquise por um canal...">
            </div>
            @for (item of channels; track item) {
            <mat-option [value]="item._id">{{item.name}}</mat-option>
            }
          </mat-select>
          <field-error-component [field]="'channel'" [label]="'Canal'" [formGroup]="formGroup"></field-error-component>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <div class="flex flex-col">

        <div class="flex ">
          @if (selectedChannel){
          <div class="flex mb-[15px] w-[100%]">
            <div class="flex flex-col w-full h-[570px]">
              <label class="font-semibold text-black">Público alvo *</label>
              <div class="flex justify-center gap-2 items-center">
                <div class="w-full flex justify-center">
                  <mat-select class="input-field" formControlName="audienceType" #audience>
                    <mat-option *ngFor="let ta of targetAudience" [value]="ta._id">
                      {{ ta.description }}
                    </mat-option>
                  </mat-select>
                  <field-error-component [field]="'audienceType'" [label]="'Publico alvo'"
                    [formGroup]="formGroup"></field-error-component>
                </div>
              </div>


              @if (audience.value === 'TAGS') {
              <div class="w-full flex gap-2">
                <div class="w-full">
                  <label class="font-semibold text-black mt-[15px]">Tags *</label>
                  <mat-select class="input-field" formControlName="tags" multiple placeholder="Selecione">
                    <div class="input-select-filter w-full p-2 mb-2">
                      <input class="input-select-filter input-field" type="text" (focus)="onFocusTags()"
                        [formControl]="filterControlTag" placeholder="Pesquise por tags...">
                    </div>
                    @for (item of allTags; track item) {
                    <mat-option [value]="item._id">{{item.title}}</mat-option>
                    }
                  </mat-select>
                  <field-error-component [field]="'tags'" [label]="'Tags'"
                    [formGroup]="formGroup"></field-error-component>
                </div>
              </div>
              }
              @if (audience.value === 'CONTACT_GROUP') {
              <div class="w-full flex gap-2">
                <div class="w-full">
                  <label class="font-semibold text-black mt-[15px]">Grupos de Contatos *</label>
                  <mat-select class="input-field" formControlName="contactGroups" multiple placeholder="Selecione">
                    <div class="w-full h-[30px] p-2 mb-2">
                      <input class="input-select-filter input-field" type="text" (focus)="onFocusGroup()"
                        [formControl]="filterControlGroup" placeholder="Pesquise por um grupo...">
                    </div>
                    @for (item of allGroups; track item) {
                    <mat-option [value]="item._id">{{ item.name }}</mat-option>
                    }
                  </mat-select>
                  <field-error-component [field]="'contactGroups'" [label]="'Grupos de Contatos'"
                    [formGroup]="formGroup"></field-error-component>
                </div>
              </div>
              }
              <div class="flex gap-2">
                <div class="flex flex-col w-full">
                  <label class="font-semibold text-black mt-[15px]">Envio *</label>

                  <mat-select formControlName="executionType" #execTime class="input-field" placeholder="Selecione">
                    <mat-option *ngFor="let exec of executeTime" [value]="exec._id">{{ exec.description }}</mat-option>
                  </mat-select>
                  <field-error-component [field]="'executionType'" [label]="'Tipo da execução'"
                    [formGroup]="formGroup"></field-error-component>
                </div>

                @if (execTime && execTime.value !== 'IMEDIATE'){
                <div class="flex flex-col w-[70%]">
                  <label class="font-semibold text-black mt-[15px]">Data *</label>
                  <div id="input-phone" class="input-field h-[46px] flex justify-between items-center w-full">
                    <input [matDatepicker]="picker" formControlName="scheduledDay" placeholder="00/00/0000"
                      class="outline-none border-none focus:ring-0 focus:border-transparent bg-transparent w-full" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                  <field-error-component [field]="'scheduledDay'" [label]="'Data'"
                    [formGroup]="formGroup"></field-error-component>
                </div>


                <div class="flex flex-col w-[60%]">
                  <label class="font-semibold text-black mt-[15px]">Hora *</label>
                  <input class="input-field" formControlName="scheduledHour" [mask]="'00:00'"
                    [dropSpecialCharacters]="false" placeholder="ex: 08:00">
                  <field-error-component [field]="'scheduledHour'" [label]="'Hora'"
                    [formGroup]="formGroup"></field-error-component>
                </div>
                }
              </div>
              <div class="textarea-wrapper" *ngIf="selectedChannel.type !== 'CLOUD_API'">
                <form [formGroup]="formGroup" class="w-full">
                  <label class="font-semibold text-black mt-[15px]">Mensagem</label>
                  <div class="textarea-container">
                    <textarea #messageTextarea formControlName="message" class="input-field h-[80px]"
                      placeholder="Digite..." maxlength="320" (paste)="onPaste($event)"></textarea>

                    <div class="icon-container flex items-center">
                      <mat-icon (click)="applyFormat('bold')" class="cursor-pointer ml-2">format_bold</mat-icon>
                      <mat-icon (click)="applyFormat('italic')" class="cursor-pointer ml-2">format_italic</mat-icon>
                      <mat-icon (click)="applyFormat('underline')"
                        class="cursor-pointer ml-2">format_underline</mat-icon>
                      <mat-icon (click)="toogleContainerEmoji()"
                        class="cursor-pointer ml-2">sentiment_satisfied</mat-icon>
                      <div class="relative" id="emoji-container">
                        <ng-container *ngIf="showContainerEmoji">
                          <emoji-mart (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false"
                            class="absolute bottom-300 right-[40px] lg:bottom-100">
                          </emoji-mart>
                        </ng-container>
                      </div>
                      <mat-icon [matMenuTriggerFor]="menuVar" class="cursor-pointer"
                        (click)="showSelectTemplate()">code</mat-icon>
                    </div>
                  </div>
                  <ng-container>
                    <mat-menu #menuVar="matMenu">
                      <button mat-menu-item (click)="selectVarContactLead()">
                        <mat-icon color="primary">person</mat-icon> Nome do contato
                      </button>
                      <button mat-menu-item (click)="selectVarGreeting()">
                        <mat-icon color="primary">pan_tool</mat-icon> Saudação
                      </button>
                      <button mat-menu-item (click)="selectVarLinkChannel()">
                        <mat-icon color="primary">insert_link</mat-icon> Link canal Whatsapp
                      </button>
                      <button mat-menu-item (click)="selectVarOperatorName()">
                        <mat-icon color="primary">perm_contact_calendar</mat-icon> Nome atendente
                      </button>
                    </mat-menu>
                  </ng-container>
                </form>
              </div>




              <div *ngIf="selectedChannel.type === 'CLOUD_API'" class="rounded-lg mt-[15px]  shadow-campaign-template">
                <wa-template-select class="" [channel]="selectedChannel"
                  (onSelectTemplate)="onSelectTemplate($event)" />
              </div>
            </div>
          </div>

          <div class="w-[30%]">
            <div class="flex flex-col w-[100%]" *ngIf="this.selectedChannel?.type === 'CLOUD_API'">
              <campaign-wa-template-message [channel]="selectedChannel" [template]="selectedTemplate"
                (templateParams)="setTemplateMessage($event)" [initTemplateData]="selectedTemplateDataInit" />
            </div>

            <div class="flex flex-col w-[100%]" *ngIf="selectedChannel?.type === 'EVOLUTION_API' || selectedChannel?.type === 'EVOLUTION_GO_API'" >
              <campaign-evo-template [inputText]="formGroup.get('message').value"
                (paramsTemplate)="onTemplateParamsReceived($event)"></campaign-evo-template>
            </div>

            <!-- <div class="flex flex-col w-[100%]" *ngIf="selectedChannel.type === 'EVOLUTION_API'">
              <campaign-evo-template [channel]="selectedChannel"/>
          </div> -->

          </div>
          }

        </div>

      </div>

    </div>
    <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-[100px] mb-4">
      <button
        class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
        (click)="goBack()">Cancelar</button>
      <button
        class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
        (click)="confirmSaveCampaign()">Salvar</button>
    </div>
  </form>
</div>