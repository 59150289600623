import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, CampaignSendMessage, CampaignSendMessageService, Pager, Tag } from 'lib-trend-core';
import { FormControl } from '@angular/forms';

export interface CampaignSaveConfirmData {
  campaignId: string;
  audienceTags: Tag[];
}

@Component({
  selector: 'campaign-send-message',
  templateUrl: './campaign-send-message.component.html',
  styleUrls: ['./campaign-send-message.component.scss']
})
export class CampaigSendMessageComponent extends AbstractComponent implements OnInit {
  pager: Pager<CampaignSendMessage> = new Pager<CampaignSendMessage>({ perPage: 5 });
  filteredCount: any = 0;
  statusList: string[] = ['ERROR', 'SUCCESS', 'TOTAL'];
  statusControl = new FormControl('TOTAL');
  dynamicStatusLabel: string = 'Total';

  statusTranslations: { [key: string]: string } = {
    'ERROR': 'Erro',
    'SUCCESS': 'Sucesso',
    'TOTAL': 'Todos'
  };

  allMessages: CampaignSendMessage[] = [];
  filteredMessages: CampaignSendMessage[] = []; 

  constructor(
    private cd: ChangeDetectorRef,
    injector: Injector,
    private campaignSendMessageService: CampaignSendMessageService,
    @Inject(MAT_DIALOG_DATA) public data: CampaignSaveConfirmData,
    private dialogRef: MatDialogRef<CampaigSendMessageComponent>
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.getList();

    this.statusControl.valueChanges.subscribe(selectedStatus => {
      this.applyFilter(selectedStatus);
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  getList() {
    this.loadingContent = true;
  
    this.searchParams = {
      campaign: this.data.campaignId,
      company: this.getIDCurrentCompany(),
    };
  
    const selectedStatus = this.statusControl.value;
    if (selectedStatus !== 'TOTAL') {
      this.searchParams['status'] = this.getStatusInEnglish(selectedStatus);
    }
  
    this.campaignSendMessageService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .subscribe({
        next: (pager: Pager<CampaignSendMessage>) => {
          this.pager = pager;
          this.filteredCount = pager.total; 
        },
        complete: () => this.loadingContent = false,
      });
  }
  
  loadPage(page: number) {
    this.pager.page = page;
    this.getList()
  }

  applyFilter(selectedStatus: string) {
    this.searchParams = {
      campaign: this.data.campaignId,
      company: this.getIDCurrentCompany(),
      status: selectedStatus !== 'TOTAL' ? this.getStatusInEnglish(selectedStatus) : null
    };
   this.updateStatusLabel()
    this.getList();
  }

  formatTags(): { title: string; bgColor: string; fontColor: string }[] {
    return this.data.audienceTags?.map(tag => ({
      title: tag.title,
      bgColor: tag.bgColor || '#ccc',
      fontColor: tag.fontColor || '#FFF'
    }));
  }

  private getStatusInEnglish(selectedStatus: string): string {
    const mapping = {
      'Erro': 'ERROR',
      'Sucesso': 'SUCCESS',
      'Todos': 'TOTAL'
    };
    return mapping[selectedStatus] || selectedStatus;
  }

  updateStatusLabel(): void {
    const selectedStatus = this.statusControl.value;

    if (selectedStatus === 'SUCCESS') {
      this.dynamicStatusLabel = 'Status concluídos com sucesso';
    } else if (selectedStatus === 'ERROR') {
      this.dynamicStatusLabel = 'Status concluídos com erro';
    } else {
      this.dynamicStatusLabel = 'Total';
    }
  }
}