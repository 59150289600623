import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Campaign, CampaignAudienceType, CampaignExecutionStatus, CampaignService, Channel, ChannelService, ChannelTypeEnum, Company, Contact, ContactGroup, ContactGroupService, ExecutionType, FirebaseService, Pager, Tag, TagService, TagTypeEnum, Traduct, WaTemplateResponse } from 'lib-trend-core';
import { debounceTime } from 'rxjs';
import { CampaignTemplateListComponent, TemplateMessage } from '../campaign-template-list-dialog/campaign-template-list.component';
import { CampaingSaveConfirmModalComponent } from '../campaing-save-confirm-modal/campaing-save-confirm-modal.component';
import { CampaingSaveConfirmData } from './../campaing-save-confirm-modal/campaing-save-confirm-modal.component';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrl: './campaign-form.component.scss'
})
export class CampaignFormComponent extends AbstractComponent {
  filterControlTag: FormControl = new FormControl('');
  filterControlGroup: FormControl = new FormControl('');
  filterControlChannel: FormControl = new FormControl('');

  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;

  showContainerEmoji: boolean = false;
  translator!: Traduct;
  campaign: Campaign;
  isUpdate: boolean = false;

  channels: Array<Channel>;
  allChannel: Array<Channel> = new Array<Channel>();
  selectedChannel: Channel;

  errorMessage = "";
  execMessage = null
  selectedContact: Contact = null;
  selectedTemplate: Partial<WaTemplateResponse>
  selectedTemplateData: Partial<TemplateMessage>;
  selectedTemplateDataInit: Partial<TemplateMessage>;

  tags: any[];
  allTags: Array<Tag> = new Array<Tag>();

  contactGroups: any[];
  allGroups: Array<ContactGroup> = new Array<ContactGroup>();

  pager: Pager<any> = new Pager<any>({ perPage: 10 });

  targetAudience = [
    { _id: CampaignAudienceType.ALL_LEADS, description: 'Todos os leads' },
    { _id: CampaignAudienceType.TAGS, description: 'Tags' },
    { _id: CampaignAudienceType.CONTACT_GROUP, description: 'Grupo de Contato' },]

  executeTime = [
    { _id: ExecutionType.IMEDIATE, description: 'Execução Imediata' },
    { _id: ExecutionType.SCHEDULED, description: 'Execução Agendada' }]

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public campaignService: CampaignService,
    public channelService: ChannelService,
    public tagService: TagService,
    public contactGroupService: ContactGroupService,
    public datePipe: DatePipe,
    public firebaseService: FirebaseService,
  ) {
    super(injector);
    this.campaign = {
      metadata: {}
    } as Campaign;
    this.createForm();
  }

  async ngOnInit() {
    const idCampaign = this.getParam('id');
    this.isUpdate = !!idCampaign;

    this.loadAllChannels();
    this.fetchFilterChannel();

    this.loadAllTags();
    this.fetchFilterTags();

    this.loadAllContactGroups();
    this.fetchFilterGroups();

    if (idCampaign) {
      this.campaignService.getById(idCampaign).subscribe((campaign: Campaign) => {
        this.campaign = campaign;
        this.selectedChannel = campaign.channel;

        const formObject = {
          ...this.campaign,
          channel: this.selectedChannel._id,
        };

        this.selectedTemplate = campaign.metadata.template;

        this.selectedTemplateDataInit = {
          params: campaign.metadata.templateParams,
          imageFile: campaign.metadata.templateImage,
        };

        if (CampaignAudienceType.TAGS === campaign.audienceType) {
          formObject['tags'] = campaign.audienceTags.map(t => t._id);
          this.checkTags();
        }

        if (CampaignAudienceType.CONTACT_GROUP === campaign.audienceType) {
          formObject['contactGroups'] = campaign.audienceContactGroup.map(t => t._id);
          this.checkContactGroups();
        }

        if (ExecutionType.SCHEDULED === campaign.executionType) {
          formObject['scheduledDay'] = this.datePipe.transform(campaign.scheduledDate, 'yyyy-MM-dd');
          formObject['scheduledHour'] = this.datePipe.transform(campaign.scheduledDate, 'HH:mm');
        }

        this.formGroup.patchValue(formObject);
      });
    }
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.campaign.name, [Validators.required]),
      channel: new FormControl(this.selectedChannel?._id || '', [Validators.required]),
      audienceType: new FormControl(CampaignAudienceType.ALL_LEADS),
      scheduledDay: new FormControl(''),
      scheduledHour: new FormControl(''),
      executionType: new FormControl(ExecutionType.IMEDIATE, [Validators.required]),
      tags: new FormControl([]),
      contactGroups: new FormControl([]),
      message: new FormControl('', [])
    });

    this.formGroup.get('audienceType')?.valueChanges.subscribe(audienceType => {
      if (audienceType === CampaignAudienceType.TAGS) {
        this.formGroup.get('tags')?.setValidators([Validators.required]);
      } else {
        this.formGroup.get('tags')?.clearValidators();
      }
      this.formGroup.get('tags')?.updateValueAndValidity();
      if (audienceType === CampaignAudienceType.CONTACT_GROUP) {
        this.formGroup.get('contactGroups')?.setValidators([Validators.required]);
      } else {
        this.formGroup.get('contactGroups')?.clearValidators();
      }
      this.formGroup.get('contactGroups')?.updateValueAndValidity();
    });

    this.formGroup.get('executionType')?.valueChanges.subscribe(executionType => {
      if (executionType !== 'IMEDIATE') {
        this.formGroup.get('scheduledDay')?.setValidators([Validators.required]);
        this.formGroup.get('scheduledHour')?.setValidators([Validators.required]);
      } else {
        this.formGroup.get('scheduledDay')?.clearValidators();
        this.formGroup.get('scheduledHour')?.clearValidators();
      }

      this.formGroup.get('scheduledDay')?.updateValueAndValidity();
      this.formGroup.get('scheduledHour')?.updateValueAndValidity();
    });
  }

  checkTags() {
    if (!this.tags || !this.campaign) return;
    this.allTags = this.tags.map(t => {
      return {
        ...t,
        checked: (this.campaign?.audienceTags?.findIndex(at => at.title === t.title) >= 0)
      }
    })
  }

  checkContactGroups() {
    if (!this.contactGroups || !this.campaign) return;
    this.allGroups = this.contactGroups.map(t => {
      return {
        ...t,
        checked: (this.campaign?.audienceContactGroup?.findIndex(at => at.name === t.name) >= 0)
      }
    })
  }

  private loadAllChannels() {
    this.channelService.getList().subscribe(channels => {
      if (channels)
        this.allChannel = channels;
        this.channels = channels;
    })
  }

  private fetchFilterChannel(): void {
    this.filterControlChannel.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('channel')?.value || null;

      this.channels = this.allChannel.filter(channel =>
        channel.name.toLowerCase().includes(value.toLowerCase()) &&
        channel._id !== currentValue
      );

      const existingChannel = this.allChannel.find(channel => channel._id === currentValue);
      if (existingChannel) {
        this.channels.push(existingChannel);
      }
    });
  }

  onFocusChannel(): void {
    this.channels = this.allChannel;
  }

  private loadAllTags() {
    this.tagService.getList().subscribe(tags => {
      if (tags) {
        this.tags = tags.filter(t => t.category === TagTypeEnum.CAMPAIGN);
        this.allTags = tags.filter(t => t.category === TagTypeEnum.CAMPAIGN);
        this.checkTags();
      }
    });
  }

  private fetchFilterTags(): void {
    this.filterControlTag.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('tags')?.value || [];

      this.allTags = this.tags.filter(tag =>
        tag.title.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(tag._id)
      );

      currentValue.forEach(tagId => {
        const existingTags = this.tags.find(tag => tag._id === tagId);
        if (existingTags) {
          this.allTags.push(existingTags);
        }
      });
    });
  }

  onFocusTags(): void {
    this.allTags = this.tags;
  }

  private loadAllContactGroups() {
    this.contactGroupService.getByIndustry().subscribe(contactGroups => {
      if (contactGroups) {
        this.contactGroups = contactGroups;
        this.allGroups = contactGroups;
        this.checkContactGroups();
      }
    });
  }

  private fetchFilterGroups(): void {
    this.filterControlGroup.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('contactGroups')?.value || [];

      this.allGroups = this.contactGroups.filter(group =>
        group.name.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(group._id)
      );

      currentValue.forEach(groupId => {
        const existingGroup = this.contactGroups.find(group => group._id === groupId);
        if (existingGroup) {
          this.allGroups.push(existingGroup);
        }
      });
    });
  }

  onFocusGroup(): void {
    this.allGroups = this.contactGroups;
  }

  showSelectTemplate(): void {
    if (this.selectedChannel.type === ChannelTypeEnum.EVOLUTION_API) {
      this.onTemplateParamsReceived('oi');
    } else {
      const dialogRef = this.dialog.open(CampaignTemplateListComponent, {
        data: this.selectedChannel
      });
      dialogRef.afterClosed().subscribe(selectedTemplate => {
        this.selectedTemplate = selectedTemplate;
      });
    }
  }

  async confirmSaveCampaign() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (!this.validate()) return;

    const campaign = this.prepareSaveCampaign();

    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {
      const idsTags = this.formGroup.get('tags')?.value;
      const idsContactGroup = this.formGroup.get('contactGroups')?.value;

      const dialogRef = this.dialog.open(CampaingSaveConfirmModalComponent, {
        data: {
          campaign,
          tags: this.tags ? this.tags.filter((tag: Tag) => idsTags.includes(tag._id)) : [],
          contactGroups: this.contactGroups ? this.contactGroups.filter((contactGroup: ContactGroup) => idsContactGroup.includes(contactGroup._id)) : [],
          channel: this.selectedChannel,
          selectedTemplate: this.selectedTemplate,
        },
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((confirmedData: CampaingSaveConfirmData) => {
        if (confirmedData) {
          campaign.cost = confirmedData.cost;
          campaign.leadCount = confirmedData.leadCount;
          this.saveCampaign(campaign);
        }
      });
    } else if (this.selectedChannel.type === ChannelTypeEnum.EVOLUTION_API || this.selectedChannel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
      const idsTags = this.formGroup.get('tags')?.value;
      const idsContactGroup = this.formGroup.get('contactGroups')?.value;

      const dialogRef = this.dialog.open(CampaingSaveConfirmModalComponent, {
        data: {
          campaign,
          tags: this.tags ? this.tags.filter((tag: Tag) => idsTags.includes(tag._id)) : [],
          contactGroups: this.contactGroups ? this.contactGroups.filter((contactGroup: ContactGroup) => idsContactGroup.includes(contactGroup._id)) : [],
          channel: this.selectedChannel,
        },
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((confirmedData: CampaingSaveConfirmData) => {
        if (confirmedData) {
          campaign.leadCount = confirmedData.leadCount;
          this.saveCampaign(campaign);
        }
      });
    }
  }

  prepareSaveCampaign(): Campaign {
    const form = { ...this.formGroup.value };
    const company = { _id: this.getIDCurrentCompany() } as Company;

    const campaign = {
      name: form.name,
      channel: form.channel,
      company,
      scheduledDate: new Date(),
      executionType: ExecutionType.IMEDIATE,
      audienceType: CampaignAudienceType.ALL_LEADS,
      audienceTags: null,
      status: CampaignExecutionStatus.WAITING,
      operator: this.getCurrentUserUser(),
    } as Campaign;

    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {
      campaign.metadata = {
        template: this.selectedTemplate,
        templateName: this.selectedTemplate.name,
        templateLanguage: this.selectedTemplate.language,
        templateParams: this.selectedTemplateData?.params,
        templateImage: this.campaign.metadata?.templateImage,
      };
    } else if (this.selectedChannel.type === ChannelTypeEnum.EVOLUTION_API || this.selectedChannel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
      campaign.metadata = {
        nonTemplateMidia: this.campaign.metadata?.nonTemplateMidia || '',
        nonTemplateType: this.campaign.metadata?.nonTemplateType || '',
        nonTemplateContentType: this.campaign.metadata?.nonTemplateContentType || '',
        nonTemplateMessage: this.campaign.metadata?.nonTemplateMessage || '',
      };

    }
    this.prepareScheduledCampaign(form, campaign);
    this.prepareAudienceTagsCampaign(form, campaign);
    this.prepareAudienceContactGroupsCampaign(form, campaign);

    return campaign;
  }

  private async saveCampaign(campaign: Campaign) {
    const imageFile = this.selectedTemplateData?.imageFile;
    if (imageFile) {
      campaign.metadata.templateImage = imageFile;
    }
    this.isUpdate ? this.update(campaign) : this.create(campaign);
  }


  private prepareAudienceTagsCampaign(form: any, campaign: Campaign) {
    if (form.audienceType === CampaignAudienceType.TAGS) {
      campaign.audienceType = CampaignAudienceType.TAGS;
      campaign.audienceTags = form.tags;
    } else {
      campaign.audienceTags = null;
    }
  }

  private prepareAudienceContactGroupsCampaign(form: any, campaign: Campaign) {
    if (form.audienceType === CampaignAudienceType.CONTACT_GROUP) {
      campaign.audienceType = CampaignAudienceType.CONTACT_GROUP;
      campaign.audienceContactGroup = form.contactGroups;
    } else {
      campaign.audienceContactGroup = null;
    }
  }

  private prepareScheduledCampaign(form: any, campaign: Campaign) {
    if (form.executionType === ExecutionType.SCHEDULED) {
      const scheduledDateHr = form.scheduledHour.split(':');

      campaign.executionType = ExecutionType.SCHEDULED;
      campaign.scheduledDate = new Date(form.scheduledDay);
      campaign.scheduledDate.setHours(scheduledDateHr[0]);
      campaign.scheduledDate.setMinutes(scheduledDateHr[1]);
    } else {
      campaign.scheduledDate = new Date();
    }
  }

  private validate() {
    const form = { ...this.formGroup.value };

    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {

      if (!this.selectedTemplate) {
        this.alertService.error('selecione um template')
        return false;
      }

      if (this.selectedTemplate.headerComponent?.format === 'IMAGE' &&
        !(this.selectedTemplateData?.imageFile)) {
        this.alertService.error('Imagem do template não foi informada.')
        return false;
      }

      const templateParams = this.selectedTemplateData?.params || ['', '', '', ''];

      if (this.selectedTemplate.bodyComponent.text.includes("{{1}}") && !templateParams[0]) {
        this.alertService.error('Corpo da variável 1 não foi definido.')
        return false;
      }
      if (this.selectedTemplate.bodyComponent.text.includes("{{2}}") && !templateParams[1]) {
        this.alertService.error('Corpo da variável 2 não foi definido.')
        return false;
      }
      if (this.selectedTemplate.bodyComponent.text.includes("{{3}}") && !templateParams[2]) {
        this.alertService.error('Corpo da variável 3 não foi definido.')
        return false;
      }
      if (this.selectedTemplate.bodyComponent.text.includes("{{4}}") && !templateParams[3]) {
        this.alertService.error('Corpo da variável 4 não foi definido.')
        return false;
      }
    }

    if (form.audienceType === CampaignAudienceType.TAGS && !form.tags) {
      this.alertService.error('Selecione ao menos uma tag do público alvo.')
      return false;
    }

    if (form.audienceType === CampaignAudienceType.CONTACT_GROUP && !form.contactGroups) {
      this.alertService.error('Selecione ao menos um grupo de contato do público alvo.')
      return false;
    }

    if (form.executionType === ExecutionType.SCHEDULED) {
      if (!form.scheduledDay) {
        this.alertService.error('Informe a data de agendamento do envio.')
        return false;
      }
      if (!form.scheduledHour) {
        this.alertService.error('Informe a hora do agendamento do envio.')
        return false;
      }
    }
    return true;
  }

  private create(campaign: Campaign): void {
    this.campaignService.create(campaign).subscribe({
      next: (value) => {
        this.alertService.success('Campanha incluída com sucesso.');
        this.goBack();
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
      complete: () => {
      },
    });
  }

  private update(campaign: Campaign): void {
    this.campaignService.update(this.campaign._id, campaign).subscribe({
      next: (value) => {
        this.alertService.success('Campanha alterada com sucesso.');
        this.goBack();
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
      complete: () => {
      },
    });
  }

  selectChannel(channel: any): void {
    this.selectedChannel = this.channels.find(c => c._id === channel);
  }

  setTemplateMessage(templateMessage: TemplateMessage): void {
    this.selectedTemplateData = templateMessage
  }

  onSelectTemplate(template: WaTemplateResponse): void {
    this.selectedTemplate = template;
  }

  onTemplateParamsReceived(templateData: any): void {
    this.campaign.metadata = {
      ...this.campaign.metadata,
      nonTemplateMidia: templateData.nonTemplateMidia,
      nonTemplateType: templateData.nonTemplateType,
      nonTemplateContentType: templateData.nonTemplateContentType,
      nonTemplateMessage: templateData.nonTemplateMessage
    };
  }

  selectVarContactLead(): void {
    const currentValue = this.formGroup.get('message')?.value || '';
    this.formGroup.get('message')?.setValue(currentValue + '{{NOME}}');
  }

  selectVarGreeting(): void {
    const currentHour = new Date().getHours();
    let greeting = '';

    if (currentHour >= 0 && currentHour < 12) {
      greeting = 'Bom dia';
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = 'Boa tarde';
    } else {
      greeting = 'Boa noite';
    }

    const currentValue = this.formGroup.get('message')?.value || '';
    this.formGroup.get('message')?.setValue(currentValue + ` ${greeting}`);
  }

  selectVarLinkChannel(): void {
    const currentValue = this.formGroup.get('message')?.value || '';
    this.formGroup.get('message')?.setValue(currentValue + ' {{LINK_CHANNEL}}');
  }

  selectVarOperatorName(): void {
    const currentValue = this.formGroup.get('message')?.value || '';
    this.formGroup.get('message')?.setValue(currentValue + '{{NOME_OPERADOR}}');
  }

  updateInputVariable(variableName: string): void {
    const inputField = this.formGroup.get('inputText');
    if (inputField) {
      const currentValue = inputField.value || '';
      const newValue = `${currentValue} ${variableName}`;
      inputField.setValue(newValue);
    }
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji;
  }

  addEmoji(event: any): void {
    const messageFg = this.formGroup.get('message');
    const currentText = messageFg.value || '';

    messageFg.setValue(currentText + event.emoji.native);
  }

  closedContainerEmoji(): void {
    if (this.showContainerEmoji) {
      this.showContainerEmoji = false;
    }
  }

  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    if (!selectedText) return;

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        return;
    }

    textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
    this.updateMessage();
  }

  updateMessage() {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const messageWithoutLineBreaks = textarea.value;
    this.formGroup.patchValue({ message: messageWithoutLineBreaks });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    pastedData = pastedData
      .replace(/<p[^>]*>/g, '\n')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/?html[^>]*>/g, '')
      .replace(/<\/?body[^>]*>/g, '')
      .replace(/<!--.*?-->/g, '')
      .replace(/<\/?br[^>]*>/g, '\n')
      .replace(/<\/?strong[^>]*>/g, '*')
      .replace(/<\/?b[^>]*>/g, '*')
      .replace(/<\/?em[^>]*>/g, '_')
      .replace(/<\/?i[^>]*>/g, '_')
      .replace(/<\/?u[^>]*>/g, '~$&~');

    pastedData = pastedData.replace(/\n{2,}/g, '\n\n').trim();

    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    textarea.value =
      textarea.value.substring(0, start) +
      pastedData +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + pastedData.length, start + pastedData.length);
    textarea.focus();
    this.updateMessage();
  }

}


